import { axios } from '@/utils/request'

export function getCmsMetaPage(type, parameter) {
  return axios({
    url: `cms/meta/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function createCmsMetaInfo(type, data) {
    return axios({
      url: `cms/meta/${type}/create`,
      method: 'post',
      data
    })
}

export function updateCmsMetaInfo(type, data) {
    return axios({
        url: `cms/meta/${type}/update`,
        method: 'post',
        data
    })
}

export function deleteCmsMetaInfo(type, data) {
    return axios({
        url: `cms/meta/${type}/delete`,
        method: 'post',
        data
    })
}

export function getCmsMetaOption(type) {
  return axios({
      url: `cms/meta/${type}/option`,
      method: 'get'
  })
}

export function getCmsPage(type, parameter) {
  return axios({
    url: `cms/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function createCmsInfo(type, data) {
  return axios({
    url: `cms/${type}/create`,
    method: 'post',
    data
  })
}

export function saveCmsInfo(type, data) {
  return axios({
    url: `cms/${type}/save`,
    method: 'post',
    data
  })
}

export function getCmsInfoDetail(type, params) {
  return axios({
    url: `cms/${type}/detail`,
    method: 'get',
    params
  })
}

export function updateCmsInfo(type, data) {
  return axios({
      url: `cms/${type}/update`,
      method: 'post',
      data
  })
}

export function deleteCmsInfo(type, data) {
  return axios({
      url: `cms/${type}/remove`,
      method: 'post',
      data
  })
}

export function confirmCmsInfo(data) {
  return axios({
    url: `cms/check_in/confirm_check`,
    method: 'post',
    data
  })
}

export function unConfirmCmsInfo(data) {
  return axios({
    url: `cms/check_in/un_confirm_check`,
    method: 'post',
    data
  })
}

export function printCmsBill(data) {
  return axios({
    url: `cms/check_in/print_cms_bill`,
    method: 'post',
    data
  })
}

// 查验列表
export function getCmsInfoPage(data) {
  return axios({
    url: `cms/check_in/check_list`,
    method: 'post',
    data
  })
}

// 下发仓储
export function storeCmsInfo(data) {
  return axios({
      url: `cms/check_in/store`,
      method: 'post',
      data
  })
}

// 查验统计报表
export function getCmsStaticsPage(type, parameter) {
  return axios({
    url: `cms/cms_statics/${type}`,
    method: 'get',
    params: parameter
  })
}

// 获取查验委托人
export function getCmsMetaConsignor() {
  return axios({
    url: `cms/meta/consignor/get_contractor_list`,
    method: 'get'
  })
}

// 导出查验统计报表
export function exportCmsReport(type, parameter) {
  return axios({
    url: `cms/cms_statics/${type}`,
    method: 'get',
    params: parameter
  })
}

// 业务登记字段重复校验
export function checkCmsInfo(params) {
  return axios({
      url: `cms/check_in/check`,
      method: 'get',
      params
  })
}

export function getCmsCharge(params) {
  return axios({
      url: `cms/finance/charge_list`,
      method: 'post',
      data: params
  })
}

// 导出查验应收对账单
export function exportCmsChargeReport(parameter) {
  return axios({
    url: `cms/finance/export_storage`,
    method: 'get',
    params: parameter
  })
}

// 导出查验应收对账单
export function exportCmsSourseReport(parameter) {
  return axios({
    url: `wms/business/in/export_cms_sourse`,
    method: 'get',
    params: parameter
  })
}

export function cmsChargeAudit(type, params) {
  return axios({
      url: `cms/finance/${type}`,
      method: 'post',
      data: params
  })
}

export function cmsChargeApplyTicket(params) {
  return axios({
      url: `cms/finance/ticket_apply`,
      method: 'post',
      data: params
  })
}

export function getCmsBalancePage(type, params) {
  return axios({
    url: `/cms/balance/${type}`,
    method: 'get',
    params
  })
}

export function getWmsCmsInfoPage(params) {
  return axios({
    url: `/wms/cms/list_check`,
    method: 'post',
    data: params
  })
}

export function getCmsTicketInfo(params) {
  return axios({
    url: `/cms/check_in/get_ticket`,
    method: 'get',
    params
  })
}

export function delCmsTicket(params) {
  return axios({
    url: `/cms/check_in/del_ticket`,
    method: 'post',
    params
  })
}

export function printCmsTicket(params) {
  return axios({
    url: `/cms/check_in/print_cms_ticket`,
    method: 'post',
    data: params
  })
}

export function exportCmsSource(data) {
  return axios({
    url: '/wms/business/in/export_cms_source',
    method: 'post',
    data
  })
}

export function exportCheckInSheet(data) {
  return axios({
    url: `/cms/check_in/exportCheckInSheet`,
    method: 'post',
    data
  })
}

export function getCompany(params) {
  return axios({
    url: `/cms/meta/customs_company/getCustomsCompany`,
    method: 'get',
    params
  })
}

export function getUnChargeTicket(data) {
  return axios({
    url: `/cms/check_in/get_un_charge_ticket`,
    method: 'post',
    data
  })
}

export function getCmsInit() {
  return axios({
    url: `cms/check_in/init`,
    method: 'get'
  })
}

export function changeDefaultDate(params) {
  return axios({
    url: `cms/check_in/changeDefaultDate`,
    method: 'get',
    params
  })
}

export function bacthOutbound(data) {
  return axios({
    url: `/cms/check_in/bacthOutbound`,
    method: 'post',
    data
  })
}
